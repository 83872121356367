import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'

import Header from './header/Header'
import Content from './content/Content'
import Footer from './footer/Footer'
import './App.css'

const App = () => {
  return (
    <>
      <div className="preloader">
        <div className="preloader-animation">
          <div className="preloader-spinner">
          </div>
        </div>
      </div>
      < div className="page-scroll" >
        <div id="page_container" className="page-container full-width-container theme-style-light" data-animation="fadeInLeft">
          <Router>
            <Header />
            <Content />
            <Footer />
          </Router>
        </div>
      </div>
    </>
  )
}

export default App
