import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'

import Home from './home/Home'
import Resume from './resume/Resume'
import Blog from './blog/Blog'

const Content = () => {
  return (
    <div id="main" className="site-main">
      <div id="main-content" className="single-page-content">
        <div id="primary" className="content-area">
          <Routes>
            <Route path="/home" element={<Home/>} />
            <Route path="/resume" element={<Resume/>} />
            <Route path="/blog" element={<Blog/>} />
            <Route index element={<Navigate to="/home" />} />
          </Routes>
        </div>
      </div>
    </div>
  )
}

export default Content