import React from 'react';
import './Switch.css'

const Switch = () => {
  const toggleSwitch = (event?: any) => {
    if(event?.target?.checked) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
  }

  return (
    <label className="switch">
      <input
        type="checkbox" 
        onChange={(event) => toggleSwitch(event)}
      />
      <span className="slider round"></span>
    </label>
  )
};

export default Switch;
