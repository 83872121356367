import React from 'react';

import education from './education.json';
import companies from './companies.json';
import events from './events.json';
import { Experience } from './Experience';
import { Event } from './Event';

import eneiImg from '../../images/enei-2020.jpg';
import pixelsCampImg from '../../images/pixels-camp.jpg';

const educationArray: Array<Experience> = education;
const companyArray: Array<Experience> = companies;
const eventsArray: Array<Event> = events;

const images: { [key:string]: string} = {
  'enei-2020.jpg': eneiImg,
  'pixels-camp.jpg': pixelsCampImg,
};

const Resume = () => (
  <div id="primary" className="content-area">
    <div className="page-title">
      <h1>Resume</h1>
      <div className="page-subtitle">
        <h4>Education and latest experiences</h4>
      </div>
    </div>
    <div id="content" className="page-content site-content single-post" role="main">
      <article id="post-171" className="post-171 page type-page status-publish hentry">
        <div className="entry-content">
          <div className="fw-page-builder-content">
            <section className="fw-main-row  ">
              <div className="fw-container">
                <div className="row">
                  <div className="col-xs-12 col-sm-6">
                    <div id="col_inner_id-5eabc2d2967eb" className="fw-col-inner" data-paddings="0px 0px 0px 0px">
                      <div className="block-title">
                        <h2><i className="fa fa-briefcase" aria-hidden="true"></i> Experience</h2>
                      </div>
                      <div id="timeline-5eabc2d296690" className="timeline clearfix">
                        {
                          companyArray.map((element, expId) => (
                            <div key={`comp_exp_${expId}`} className="timeline-item clearfix">
                              <h5 className="item-period ">{element.period}</h5>
                              <span className="item-company">{element.company}, <i>{element.sector}</i></span>
                              <h4 className="item-title">{element.title}</h4>
                              {
                                element.text.map((textBlock, textBlockIdx) => (
                                  <p key={`comp_textBlock_${textBlockIdx}`}>{textBlock}</p>
                                ))
                              }
                            </div>
                          ))
                        }
                      </div>
                    </div>
                    <div id="col_inner_id-5eabc2d296354" className="fw-col-inner" data-paddings="0px 0px 0px 0px">
                      <div className="block-title">
                        <h2><i className="fa fa-graduation-cap" aria-hidden="true"></i> Education</h2>
                      </div>
                      <div id="timeline-5eabc2d296690" className="timeline clearfix">
                        {
                          educationArray.map((element, expIdx) => (
                            <div key={`edu_experience_${expIdx}`} className="timeline-item clearfix">
                              <h5 className="item-period ">{element.period}</h5>
                              <span className="item-company">{element.company}</span>
                              <h4 className="item-title">{element.title}</h4>
                              {
                                element.text.map((textBlock, textBlockIdx) => (
                                  <p key={`edu_textBlock_${textBlockIdx}`}>{textBlock}</p>
                                ))
                              }
                            </div>

                          ))
                        }
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-6">
                    <div id="col_inner_id-5eabc2d296354" className="fw-col-inner" data-paddings="0px 0px 0px 0px">
                      <div className="block-title">
                        <h2><i className="fa fa-rocket" aria-hidden="true"></i> Events</h2>
                      </div>
                      <div id="timeline-5eabc2d296690" className="timeline clearfix">
                        {
                          eventsArray.map((event, expIdx) => (
                            <div key={`events_${expIdx}`} className="timeline-item clearfix">
                              <h5 className="item-period ">{event.period}</h5>
                              <div className="certificate-item clearfix">
                                <div className="certi-logo" style={{ padding: '0px' }}>
                                  <img src={images[event.imageLink]} alt={event.imageLink} />
                                </div>
                                <div className="certi-content">
                                  <div className="certi-title">
                                    <h4>{event.title}</h4>
                                  </div>
                                  <div className="certi-id">
                                    <p>{event.text}</p>
                                  </div>
                                  <div className="certi-date">
                                    <span>{event.date}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </article>
    </div>
  </div>
);

export default Resume;