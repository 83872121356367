import React from 'react';
import { Link } from 'react-router-dom';

import { Post } from './Post';

import posts from './posts.json';

const postArray: Array<Post> = posts;

const Blog = () => (
  <div id="primary" className="content-area">
    <div className="page-title">
      <h1>Blog</h1>
      <div className="page-subtitle">
        <h4>Writing is a great way to learn</h4>
      </div>
    </div>

    <div id="content" className="page-content site-content single-post" role="main">
      <article id="post-86" className="post-86 page type-page status-publish hentry">
        <div className="entry-content">
          <div className="fw-page-builder-content">
            <section className="fw-main-row">
              <div className="fw-container">
                <div className="row">
                  <div className="col-xs-12 col-sm-12 ">
                    <div className="fw-col-inner" data-paddings="0px 0px 0px 0px">
                      <div className="fw-divider-space" style={{ paddingTop: '7px' }}></div>
                      <div className="blog-masonry three-columns clearfix">
                        {
                          postArray.map((post, postIdx) => (
                            <div key={`blog_post_${postIdx}`} className="item">
                              <div className="blog-card">
                                <div className="media-block">
                                  <a href={post.articleLink} target="_blank" rel="noopener noreferrer">
                                    <img width="1920" height="900"
                                      src={post.imageLink}
                                      className="attachment-blog-masonry-image-three-c size-blog-masonry-image-three-c wp-post-image"
                                      alt={post.title}
                                      title={post.title}
                                      sizes="(max-width: 768px) 92vw, (max-width: 992px) 450px, (max-width: 1200px) 597px, 25vw"
                                      style={{maxHeight: '200px'}}/>
                                    <div className="mask"></div>
                                  </a>
                                </div>
                                <div className="post-info">
                                  <div className="post-date">{post.date}</div>
                                  <a href={post.articleLink}>
                                    <h4 className="blog-item-title" style={{minHeight:'40px'}}>{post.title}</h4>
                                  </a>
                                </div>
                                <div style={{minHeight:'65px'}}>
                                  <span className="tags">
                                    {
                                      post.tags.map((tag, tagIdx) => (
                                        <Link key={`blog_post_tag_${tagIdx}`} to={`/tag/${tag}`} rel="tag">{tag}</Link>
                                      ))
                                    }
                                  </span>
                                </div>
                              </div>
                            </div>
                          ))
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </article>
    </div>
  </div>
);

export default Blog;